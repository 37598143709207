.Navigation {
  &__accountButton {
    padding: 12px 24px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    color: black;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  @media screen and (max-width: 410px) {
    flex-direction: column !important;

    &__items {
      width: 100% !important;
      .button {
        width: 100% !important;
        margin-right: 2px;
        margin-left: 2px;
      }
    }

    &__action {
      width: 100% !important;
      margin: 0 !important;

      & .button,
      & .Navigation__accountButton {
        width: 100% !important;
        margin-top: 8px;
      }
    }
  }

  .discordButton.discordButton {
    padding-right: 16px;
    max-height: 44px;
    svg {
      margin: 0;
      path {
        fill: black;
      }
    }
  }
}
