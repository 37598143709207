.MyStats {
  box-sizing: border-box;

  .goToUnionButton svg path {
    fill: black;
  }

  &__header {
    p {
      text-transform: uppercase;
      font-size: 14px;
      line-height: 20px;
    }

    a {
      color: white !important;
      background: #171717 !important;
    }
  }

  .button--secondary {
    background: #171717;

    &:hover:not(.button--selected) {
      background: rgba(0, 0, 0, 0.6) !important;
    }

    &:active:not(.button--selected) {
      background: rgba(0, 0, 0, 0.9) !important;
    }
  }

  &__grid {
    display: grid;
    width: 100%;
    grid-gap: 6px;
    grid-template-columns: 1fr 1fr;

    &__item {
      background: #F4F4F5;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      white-space: nowrap;

      &:nth-child(-n + 4) .heading {
        color: #27272a;
      }

      label {
        margin: 0;
        color: #71717a !important;
      }
    }
  }
}
