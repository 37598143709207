@import "@unioncredit/ui/lib";

@font-face {
  font-family: "davinci";
  src: url("fonts/TRJNDaVinci-Display.ttf") format("truetype");
}

body {
  background: white;

  .fg-zinc500 {
    color: #71717a !important;
  }
}

ul {
  list-style: initial;
  padding-left: 20px;

  li {
    font-size: 12px;
    line-height: 16px;
  }
}

.uppercase {
  text-transform: uppercase;
}

.layout {
  max-width: 1253px;
}

.crossout {
  text-decoration: line-through;
}

.logo {
  width: 141px;
  margin: 0 auto;
}

.button {
  min-height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: none !important;
  color: black !important;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.4);
  }

  &:active {
    border: 1px solid rgba(0, 0, 0, 0.4);
  }

  &--black {
    color: white !important;
    background: #27272A !important;
  }

  &--primary {
    min-height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.4);
    }

    &:active {
      border: 1px solid rgba(0, 0, 0, 0.4);
    }
  }

  &--secondary {
    &.button--selected {
      background: rgba(0, 0, 0, 0.1) !important;
      color: black;
    }
  }

  .loading-spinner circle {
    stroke: rgba(255, 255, 255, 0.3) !important;
  }

  &[disabled="true"],
  &[disabled],
  &[disabled]:hover,
  &[disabled="true"]:hover {
    background: #404040 !important;
    color: white !important;
    opacity: 0.4 !important;
  }
}

.loading-spinner {
  circle {
    stroke: rgba(255, 255, 255, 0.5);
  }
  path {
    fill: black;
    stroke: rgba(255, 255, 255, 1);
  }
}
