.Rules {
  box-sizing: border-box;
  max-width: 624px;

  .text,
  .heading {
    font-size: 14px;
    text-transform: uppercase;
  }

  .heading {
    color: black;
    font-weight: 400;
  }

  .indented {
    list-style: circle;
    margin-left: 15px;
  }

  ol {
    margin-top: 15px;

    li {
      margin-left: 15px;
      list-style: decimal;
    }
  }

  li,
  .label,
  td {
    color: grey;
  }

  li {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 22px;
  }

  table {
    margin-bottom: 8px;
  }

  td {
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px;
  }
}
