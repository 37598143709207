.ClaimNFT {
  border-radius: 8px;
  box-sizing: border-box;

  &__header {
    svg path {
      fill: white;
    }
  }

  &__highlightedHeader {
    color: black !important;
  }

  &__imageContainer {
    width: 185px;
    margin-right: 24px;
  }

  &__image {
    position: relative;
    width: 100%;
    border: solid 1px #525252;
    background: #404040;
    border-radius: 4px;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }

    &:before {
      content: "";
      display: block;
      padding-top: 110%;
    }
  }

  &__tickItems {
    font-size: 12px;

    .box {
      line-height: 1px;
      color: #525252;
      svg path {
        fill: #525252;
      }

      &.active {
        color: white;
        svg path {
          fill: #818cf8 !important;
        }
      }
    }
  }

  .text,
  .label {
    color: black;
  }

  li {
    color: #a3a3a3;
  }

  @media screen and (max-width: 610px) {
    display: block !important;

    &__imageContainer {
      margin-bottom: 24px;
      margin-right: 0;
      width: 100%;
    }
  }

  &__teams {
    @media (max-width: 610px) {
      flex-wrap: wrap;
    }
  }

  &__team {
    width: calc(25% + 6px);
    background: none;
    border-radius: 4px;
    position: relative;
    margin-right: -8px;
    padding: 22px 16px;
    box-sizing: border-box;

    @media (max-width: 610px) {
      width: calc(50% + 6px);
    }

    .text {
      color: inherit;
    }
    .text:nth-of-type(1) {
      font-size: 32px;
      font-style: italic;
      margin-bottom: 16px;
      font-family: davinci;
    }
    .text:nth-of-type(2) {
      font-size: 12px;
      line-height: unset;
      margin: 0;
    }

    &.unselected {
      cursor: pointer;
    }

    &--cyan {
      color: #2ea000;
      background: #00d1ff;

      &.unselected {
        color: #606060;
        background: #979797;
      }
    }
    &--magenta {
      color: #1a00ff;
      background: #fa00ff;

      &.unselected {
        color: #1C1C1C;
        background: #676767;
      }
    }
    &--yellow {
      color: #f53202;
      background: #ffc700;

      &.unselected {
        color: #1C1C1C;
        background: #4f4e4e;
      }
    }
    &--black {
      color: white;
      background: black;

      &.unselected {
        color: #1C1C1C;
        background: #444;
      }
    }
  }
}
