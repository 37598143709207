.team-square {
  width: 10px;
  height: 10px;
  border-radius: 4px;
  background: none;
  margin-left: 4px;

  &--cyan {
    background: #00d1ff;
  }
  &--magenta {
    background: #fa00ff;
  }
  &--yellow {
    background: #ffc700;
  }
  &--black {
    background: black;
  }
}

.avatarCell {
  width: 30px;
  position: relative;
  padding-right: 0px !important;
  padding-left: 8px;

  .isMember {
    position: absolute;
    bottom: 5px !important;
    left: 6px;
    width: 16px !important;
    height: 16px !important;
  }

  .avatar {
    border: 0px;
    background: #404040;
  }
}

.tableWrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;

  overflow: hidden;

  @media screen and (max-width: 620px) {
    overflow-x: scroll;
  }

  .table {
    background: none;
  }

  .table-row:nth-child(1) .table-cell {
    font-size: 12px;
    line-height: 16px;
  }

  .table-cell {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: black;
    font-size: 14px;
    line-height: 20px;
  }

  .external {
    vertical-align: middle;
    margin-left: 4px;

    & > path:nth-child(1) {
      fill: rgba(0, 0, 0, 0.1);
    }
  }

  .badge.badge--grey {
    background: rgba(0, 0, 0, 0.1);
    color: black;
  }

  .badge.badge--blue {
    color: #bdbaee;
    background: #5046e5;
  }

  .table-cell:first-of-type {
    padding-left: 16px;
  }
}
